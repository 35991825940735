import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 636.000000 177.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,177.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M0 1688 c1 -66 3 -79 11 -63 45 81 88 120 153 136 21 4 -2 7 -66 8
l-98 1 0 -82z"/>
<path d="M6204 1755 c72 -25 116 -69 141 -141 14 -38 14 -37 15 59 l0 97 -97
0 c-96 -1 -97 -1 -59 -15z"/>
<path d="M157 1706 c-47 -17 -71 -38 -91 -81 -14 -29 -16 -118 -16 -739 0
-683 1 -708 20 -745 11 -22 36 -50 57 -62 l38 -24 2989 -3 c2146 -1 3000 1
3029 9 52 14 102 63 116 114 17 64 14 1378 -3 1428 -18 50 -42 76 -91 98 -39
18 -149 19 -3025 18 -2492 0 -2991 -2 -3023 -13z m5573 -288 c-20 -95 -40
-189 -44 -208 l-6 -34 27 26 c42 39 104 68 161 75 159 18 265 -100 248 -277
-24 -260 -218 -440 -476 -440 -59 0 -139 24 -175 52 -35 28 -64 92 -65 143 0
22 35 219 77 438 l77 397 106 0 106 0 -36 -172z m-3151 147 c52 -26 64 -107
26 -162 -20 -28 -78 -47 -121 -38 -38 7 -74 52 -74 93 0 40 30 89 63 106 40
20 70 20 106 1z m-1760 -295 c44 -12 88 -55 96 -91 l7 -29 44 44 c80 81 198
107 284 64 97 -49 107 -122 55 -397 -20 -107 -41 -216 -46 -242 l-11 -49 -99
0 c-54 0 -99 1 -99 3 0 1 18 96 40 212 22 116 40 226 40 245 0 52 -24 80 -67
80 -92 0 -140 -87 -190 -349 l-37 -191 -98 0 c-71 0 -98 3 -98 12 0 7 18 109
41 227 49 258 46 301 -23 301 -104 0 -155 -100 -208 -407 l-23 -133 -99 0
c-66 0 -98 4 -98 11 0 6 20 118 45 248 40 213 75 414 75 434 0 4 40 7 88 7
l88 0 -7 -57 c-3 -32 -5 -60 -3 -61 1 -2 10 7 19 21 51 79 187 125 284 97z
m1307 -11 c27 -7 50 -13 52 -14 2 -1 -18 -103 -43 -226 -28 -141 -48 -266 -52
-336 l-6 -113 -95 0 -95 0 7 73 6 72 -22 -30 c-57 -75 -122 -114 -203 -122
-43 -5 -63 -1 -107 19 -111 51 -151 166 -113 327 44 184 176 313 365 356 73
17 226 14 306 -6z m1079 -8 c95 -42 151 -124 161 -234 7 -71 -14 -173 -47
-239 -28 -54 -101 -131 -154 -163 -90 -52 -216 -68 -314 -39 -64 19 -136 81
-163 141 -56 127 -19 313 86 430 107 119 295 164 431 104z m770 7 c-8 -35 -35
-169 -35 -174 0 -2 -17 -4 -37 -4 -20 0 -55 -9 -77 -20 -83 -42 -120 -121
-161 -342 l-27 -148 -104 0 c-77 0 -104 3 -104 13 0 6 18 104 40 217 34 173
70 410 70 457 0 10 24 13 91 13 l91 0 -7 -77 -7 -78 30 45 c50 74 125 118 205
119 34 1 37 -1 32 -21z m499 -2 l35 -15 -18 -53 c-33 -94 -26 -87 -78 -72 -68
20 -125 18 -157 -7 -46 -36 -27 -74 62 -118 58 -29 122 -89 139 -128 43 -104
-32 -236 -160 -280 -67 -23 -202 -23 -277 0 -87 26 -85 24 -60 104 l23 70 74
-24 c92 -29 142 -30 177 -2 53 42 30 79 -95 149 -97 55 -134 128 -109 218 21
81 107 150 211 172 58 12 189 4 233 -14z m-1884 10 c0 -35 -146 -771 -160
-810 -29 -77 -71 -130 -124 -155 -51 -25 -136 -37 -191 -27 l-37 7 6 47 c14
102 14 102 52 102 50 0 73 16 94 63 10 23 49 202 85 397 37 195 68 361 70 368
3 8 33 12 105 12 55 0 100 -2 100 -4z m2300 -2 c0 -3 -18 -101 -41 -217 -43
-225 -45 -287 -13 -312 60 -45 161 25 199 137 9 24 31 122 49 217 19 94 36
174 39 177 2 3 49 3 103 2 l100 -3 -57 -300 c-31 -165 -60 -321 -64 -348 l-6
-47 -94 0 c-71 0 -93 3 -91 12 3 7 7 35 10 62 l6 49 -44 -41 c-95 -87 -210
-115 -296 -70 -100 52 -108 119 -51 433 23 127 44 236 46 243 3 8 33 12 105
12 55 0 100 -3 100 -6z"/>
<path d="M5742 1093 c-46 -22 -88 -87 -112 -174 -37 -135 -19 -199 58 -199 73
0 130 45 179 140 22 45 28 68 28 127 0 62 -3 75 -24 97 -29 31 -78 34 -129 9z"/>
<path d="M1824 1106 c-102 -45 -186 -207 -160 -310 24 -95 141 -85 201 17 23
40 61 170 79 270 l7 37 -48 0 c-26 -1 -62 -7 -79 -14z"/>
<path d="M2984 1101 c-43 -26 -73 -72 -95 -143 -43 -137 -5 -238 89 -238 73 0
126 52 162 160 16 47 21 81 18 124 -4 51 -9 63 -36 87 -39 35 -91 38 -138 10z"/>
<path d="M6350 172 c0 -30 -55 -108 -95 -132 -20 -13 -51 -26 -69 -30 -21 -5
3 -8 72 -9 l102 -1 0 95 c0 52 -2 95 -5 95 -3 0 -5 -8 -5 -18z"/>
<path d="M0 88 l0 -88 103 1 c102 0 102 0 54 13 -56 15 -111 63 -138 121 l-19
40 0 -87z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
